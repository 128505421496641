export class ServiceToInvoice {
  public ServiceId: number;
  public Washing: boolean;
  public Waste: boolean;
  public Testing: boolean;
  public Reparation: boolean;
  public Impregnation: boolean;
  public Spagetti: boolean;
  public Packaging: boolean;
  public Delivery: boolean;
}
