export class BaseEntity {
  Id: number;
  Created?: Date;
  Updated?: Date;
  IsDeleted: boolean;
}

export class BaseEntityWithMultilanguageName extends BaseEntity {
  Name: string;
  NameEn: string;
  NameEs: string;
}
