export class MooringArticleProduct {
  static ApiUrl = 'api/mooring-article-products';

  Id: number;
  MooringArticleId: number;
  ProductId: number;
  ProductName?: string;
  ArticleNumber?: string;

  SupplierId?: number;
  SupplierName?: string;

  Created?: Date;
  Updated?: Date;
  IsDeleted?: boolean;
}
