import { BaseEntity } from './BaseEntity';
import { CategoryDimensionType } from './MooringArticleCategory';
import { UnitOfMeasure } from './MooringSalesOrderLine';

export class MooringFrameSalesOrderLine extends BaseEntity {
  SalesOrderId: number;
  CertificateId: number;
  ArticleProductId: number;

  ArticleNumber: string;
  SupplierName: string;

  BatchNumber: string;
  BatchNumberTo: string;
  ActualBatchNumber?: string;

  Quantity: number;
  Length: number;
}

export class CreateUpdateMooringFrameSalesOrderLine {
  Id?: number;
  SalesOrderId: number;
  CertificateId: number;

  FrameOrderCategoryId: number;

  Quantity: number;
  Length: number;
  TotalLength: number;
  _isFirst?: boolean;
  _typeMBL?: number;
  ProductType: string;

  CanEditLength: boolean;
  MooringArticleCategoriType: string;
  Type: string;
  PrimaryDimension: number;
  PrimaryDimensionType: CategoryDimensionType;
  SecondaryDimension?: number;
  SecondaryDimensionType?: CategoryDimensionType;
  Dimension: string;
  ActualBatchNumber?: string;
}

export class MooringFrameSalesOrderLineListItem {
  Id: number;
  SalesOrderId: number;

  ArticleNumber: string;
  ProductName: string;

  BatchNumber: string;
  BatchNumberTo: string;

  FrameOrderCategoryId: number;
  FrameOrderCategoryName: string;

  CertificateId: number;
  Quantity: number;
  Length: number;
  TypeMBL?: number;
  TotalLength: number;
  ProductType: string;
  Type: string;

  ActualBatchNumber?: string;

  CanEditLength: boolean;
  MooringArticleCategoriType: string;
  PrimaryDimension: number;
  PrimaryDimensionType: CategoryDimensionType;
  SecondaryDimension?: number;
  SecondaryDimensionType?: CategoryDimensionType;
  Dimension: string;

  UnitOfMeasure: UnitOfMeasure;
}
