import { Models } from './core';
import { range, readOnly } from './restutil';
export class NettingTypeService {
  public static ApiUrl = 'api/nettingtypesservice';

  Solidity?: number;
  Omfar?: number;
  Msk?: number;
  MeshOpening?: number;

  MeshSizeId?: number;
  MeshSize: Models.MeshSize;

  NylonTypeId?: number;
  NylonType: Models.NylonType;

  MaterialId?: number;
  Material: Models.Material;

  ThreadTypeId?: number;
  ThreadType: Models.ThreadType;

  ApprovedForNs9415: boolean;

  ProductId?: number;
  Product: Models.Product;

  @range(null, 100)
  Name: string;

  Id: number;

  Created?: Date;

  Updated?: Date;

  @readOnly()
  IsDeleted: boolean;

  Inactive?: boolean;
}
