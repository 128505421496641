export module FileAttachmentModels {
  export class FileAttachment {
    // this needs to be set based on what entity we are retrieving attachments for
    public static ApiUrl: string = '';

    Id: string;
    FileName: string;
    Length: number;
    FileUrl: string;
  }
}

export class FileAttachment {
  Id: string;
  FileName: string;
  Length: number;
  FileUrl: string;
  Extension?: '.pdf' | '.jpg' | '.png' | '.docx' | '.xlsx' | '.pptx' | '.txt' | '.zip' | '.csv';
}
