import { ApiProfile, apiRoute }  from 'services/rest-util';

export module RoleModels {
  export class Role {
    public static ApiUrl: string = 'api/roles';

    Id: string;
    Name: string;    
  }
}
