export class CreateOfferDto {
  public CopyNetDesignFromExistingNetId?: number;
  public QuoteNumber: string;
  public QuoteIdCRM: number;
  public CustomerId: number;
  public DepartmentId: number;
  public SiteId: number;
  public ContactId: number;
  public ProducerId: number;
  public ValidTo: Date = new Date();
  public PlannedDeliveryDate: Date;
  public DeliveryAddressId?: number;
  public UnloadingContactId?: number;
  public Visible: boolean;
  public NumberOfNets: number = 1;
  public CurrencyId: number = 1;
  public DesignTypeId?: number;
  public IsSimpleOffer?: boolean;
  public ResponsibleUserId: string;
  public IsCombiNet: boolean;
  public IsCollect: boolean;
}
