import { BaseEntity } from './BaseEntity';
import { Models } from './core';

export class ExtraEquipment extends BaseEntity {
  public static ApiUrl = 'api/extraequipments';
  ExtraEquipmentTypeId: number;
  ExtraEquipmentType: ExtraEquipmentType;

  NetId: number;
  Net: Models.Net;

  Quntity?: number;

  SinkerTube?: SinkerTube;
  SinkerTubeId?: number;

  Value1?: number;
  Value2?: number;

  Comment?: string;
}

export type CreateExtraEquipment = Omit<
  ExtraEquipment,
  'IsDeleted' | 'Created' | 'Updated' | 'ExtraEquipmentType' | 'Net'
> & { Id?: number };

export class ExtraEquipmentType extends BaseEntity {
  public static ApiUrl = 'api/extraequipmenttypes';
  Title: string;
  Type: ExtraEquipmentTypes;

  IsSinkerTube: boolean;
  IsDeadFish: boolean;

  HasQuantity: boolean;

  HasMaxWeight: boolean;
  HasMinWeight: boolean;

  HasComment: boolean;

  HasValue1: boolean;
  HasValue2: boolean;
}

export class SinkerTube extends BaseEntity {
  public static ApiUrl = 'api/sinkertubes';
  Title: string;

  MinWeight: number;
  MaxWeight: number;

  TypeId: number;
  ExtraEquipmentType: ExtraEquipmentType;
}

export enum ExtraEquipmentTypes {
  DeadfishCollector = 1,
  Liftup = 2,
  BottomRing = 3,
  SingleSinker = 4,
  CenterSinker = 5,
  DepthBottomRing = 6,
  DepthSingleSinker = 7,
  DepthCenterSinker = 8,
}
