export const CustomerDocumentType = {
  ServiceInformation: 1,
  Other: 2,
} as const;

export type CustomerDocumentType = (typeof CustomerDocumentType)[keyof typeof CustomerDocumentType];

export class CustomerDocumentAttachment {
  CustomerId: number;
  StorageFileId: number;
  DocumentType: CustomerDocumentType;
  Id: number;
  Created: Date;
  Updated: Date;
  Deleted: Date;
}
