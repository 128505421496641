import { ProductSource } from './ProductSource';

export class ErpSystem {
  public static ApiUrl = 'api/erp-system';
  Id: number;
  Title: string;
  SourceType: ProductSource;
  Created: Date;
  Updated: Date;
  IsDeleted: boolean;
}
