import { Models } from './core';
import { range, readOnly } from './restutil';

export class LoopTypeService {
  public static ApiUrl = 'api/looptypesservice';

  ApprovedForNs9415: boolean;

  ProductId?: number;
  Product: Models.Product;

  @range(null, 100)
  Name: string;

  Id: number;
  Created?: Date;
  Updated?: Date;

  @readOnly()
  IsDeleted: boolean;
}
