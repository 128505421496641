export enum InvoiceWasteBy {
  Net = 0,
  Kg = 1,
}

export const InvoiceWasteByOptions = [
  {
    value: InvoiceWasteBy.Net,
    label: 'service.invoiceWasteByNet',
  },
  {
    value: InvoiceWasteBy.Kg,
    label: 'service.invoiceWasteByKg',
  },
];
