import { BaseEntityWithMultilanguageName } from './BaseEntity';

export enum SalesOrderTypeEnum {
  Standard = 0,
  Frame = 1,
}

export class MooringSalesOrderType extends BaseEntityWithMultilanguageName {
  static ApiUrl = 'api/mooring-sales-order-types';

  Type: SalesOrderTypeEnum;
}
