import { Models } from './core';

export class ServiceInvoiceWashWasteNetTypeProduct {
  public static ApiUrl = 'api/service-invoice-wash-waste-net-type-products';

  Id: number;

  NetTypeId: number;
  NetType?: Models.NetType;

  WashProductId?: number;
  WashProduct?: Models.Product;

  WasteProductId?: number;
  WasteProduct?: Models.Product;
}
