import { Models } from './core';

export class ServiceInvoiceStandardProduct {
  public static ApiUrl = 'api/service-invoice-standard-products';

  Id: number;

  ProductId: number;
  Product?: Models.Product;

  IsForSpecialProduct: boolean;
}
