export enum CertificateProducer {
  MORENOT = 1,
  SUPPLIER = 2,
}
export class MooringSupplier {
  static ApiUrl = 'api/suppliers';

  Id: number;
  Name: string;
  CertificateProducer: CertificateProducer;

  readonly Created?: Date;
  readonly Updated?: Date;
  readonly IsDeleted?: boolean;
}
