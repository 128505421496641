import { BaseEntity } from './BaseEntity';
import {
  CreateUpdateMooringFrameSalesOrderDetails,
  MooringFrameSalesOrderDetails,
} from './MooringFrameSalesOrderDetails';
import {
  CreateUpdateMooringFrameSalesOrderLine,
  MooringFrameSalesOrderLineListItem,
} from './MooringFrameSalesOrderLine';
import { MooringSalesOrderLineListItem, CreateUpdateMooringSalesOrderLine } from './MooringSalesOrderLine';

export const AquacomSyncStatus = {
  NOT_SYNCED: 1,
  SYNC_FAILED: 2,
  SYNCED: 3,
} as const;
export type AquacomSyncStatus = (typeof AquacomSyncStatus)[keyof typeof AquacomSyncStatus];

export class MooringSalesOrder extends BaseEntity {
  static ApiUrl = 'api/mooring-sales-orders';

  ApprovedBy?: string;
  ApprovedById?: string;
  ApprovedAt?: Date;

  CertificatesZipId?: number;

  CustomerId: number;
  CustomerName: string;

  ContactId: number;
  ContactName: string;

  SiteId: number;
  SiteName: string;

  TypeId: number;

  OrderNumber: string;
  InvoiceNumber: string;

  OrderLines: MooringSalesOrderLineListItem[];

  FrameOrderLines: MooringFrameSalesOrderLineListItem[];
  FrameOrderDetails?: MooringFrameSalesOrderDetails;

  AquacomSyncStatus?: AquacomSyncStatus;
  AquacomSyncedAt?: Date;

  Note?: string;
}

export class CreateUpdateMooringSalesOrder {
  Id?: number;

  CustomerId: number;
  ContactId: number;
  SiteId: number;
  OrderNumber: string;
  InvoiceNumber: string;

  TypeId?: number;

  OrderLines: CreateUpdateMooringSalesOrderLine[];

  Note?: string;

  FrameOrderLines: CreateUpdateMooringFrameSalesOrderLine[];
  FrameOrderDetails?: CreateUpdateMooringFrameSalesOrderDetails;
}

export class MooringSalesOrderListItem {
  Id: number;
  CertificatesZipId?: number;
  ApprovedAt?: Date;
  CustomerName: string;
  LocalityName: string;
  OrderNumber: string;
  InvoiceNumber: string;
  ContactName: string;
  ContactEmail?: string;
  Note?: string;

  AquacomSyncStatus?: AquacomSyncStatus;

  OrderLines: MooringSalesOrderLineListItem[];
  FrameOrderLines: MooringFrameSalesOrderLineListItem[];
}
