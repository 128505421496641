import { Models } from './core';

export class ServiceKiloWasteProduct {
  public static ApiUrl = 'api/service-kilo-waste-products';

  Id: number;

  ProductId: number;
  Product?: Models.Product;

  CompanyId: number;
  Company?: Models.Company;
}
