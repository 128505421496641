import { Expose } from "class-transformer";
import { Impregnated } from "./Impregnated"

export type NetShape = typeof NetShape[keyof typeof NetShape]
export const NetShape = {
  STRAIGHT_SIDES:1, // DELETED
  CONED_SIDES:2, // DETELED
  CONED_NET:3,
  UNKNOWN:4, // DELETED
  CUT_CORNERS:5, // DELETED
  RECTANGULAR:6, // DELETED
  SPAGHETTI_NET:7,
  CYLINDER_NET:8,
  SQUARE_NET:9,
  COMBI_NET:10, // DELETED
  ÅTTEKANT:11, // DELETED
} as const

@Expose()
export class NetInfo {
  Id: number;
  AquacomId?: null | number;
  NetIdentifier: string
  CustomerNetId?: null | string
  CustomerName?: null | string
  CustomerIndustryId?: null | number
  // CustomerServiceInformationFileId: null
  // CustomerAquacomId: null
  // NetDimensionId: number
  // ImpregnationId: number
  // Impregnated?: boolean
  // SpecialProductDimensionId: null
  // Circumference: 100.00
  // DepthBottom: 99.00
  // HasNettingOnOuterBottom: false
  // HasSameNettingOnAllNet: false
  // CanGenerateCertificate: false
  // NetIntentId: null
  // NetIntentName: null
  // NetIntentNameEn: null
  // NetIntentNameEs: null
  // IsSpecialProduct: false
  // SpecialProductTypeId: null
  // SpecialProductTypeName: null
  // SpecialProductTypeNameEn: null
  // SpecialProductTypeNameEs: null
  NetShapeId?: null | number
  NetShapeName?: null | string
  NetShapeNameEn?: null | string
  NetShapeNameEs?: null | string
  NetTypeId?: null | number
  NetTypeName?: null | string
  NetTypeNameEn?: null | string
  NetTypeNameEs?: null | string
  NetStatusId?: null | number
  NetStatusName?: null | string
  NetStatusNameEn?: null | string
  NetStatusNameEs?: null | string
  DimensionClassId?: null | number
  ServiceCount?: null | number
  // MeshSizes: null
  // NettingTypeName": "
  // ProducerId: 8
  // ProducerName": "Egersund Net AS
  // DrawingFileId: null
  // CustomerDrawingFileId: null
  // ProductionDrawingFileId: null
  // CertificateFileId: null
  // IsMorenotNet: false
  // IsNetDesign: false
  // NetOrderId: null
  // NetOrderNetId: null
  // NetOfferId: null
  // NetOfferNetId: null
  // QuoteNumber: null
  // OrderNumber: null
  // NetProductionDataId: number
  NetPreparationId?: null | number
  // MerdNumber: null
  // Placement: null
  // PlacementType: null
  PlacementId: null | number
  PlacementNetlogId: null | number
  DiscardedDate: null | Date

  Comment?: string | null;
  ImportantComment: string;
  DeliveryDate?: Date;
  ApprovedDate?: Date;
  ValidTo?: Date;
  IsSpecialProduct: boolean;
  IsNetDesign: boolean;
  IsMorenotNet: boolean;
  Impregnated: Impregnated;
  CustomerId: number;
  SpecialProductTypeId?: number;
  ImpregnationId?: number;
  NetDimensionId?: number;
  SpecialProductDimensionId?: number;
  NetProductionDataId?: number;
  // NetPreparation: NetPreparation;
  ProducerId?: number;
  DrawingFileId?: number;
  ProductionDrawingFileId?: number;
  CustomerDrawingFileId?: number;
  CertificateFileId?: number;
  Locked: boolean;
  CanGenerateCertificate: boolean;
  Created?: Date;

  // IsCollect: boolean; TODO: Add this...
}

