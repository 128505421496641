
// NOTE: Currently just a duplicate of OfferModels.ts

export class CreateNetDto {
  public Id: number;
  public IsSpecialProduct: boolean;
  public SpecialProductTypeId: number;
  public NetIdentifier: string;
  public QuoteNumber: string;
  public CustomerId: number;
  public DepartmentId: number;
  public SiteId: number;
  public ContactId: number;
  public ProducerId: number;
  public ValidTo: Date;
}
