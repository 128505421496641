import { Expose } from 'class-transformer';
import { MooringArticleType } from './MooringArticleType';

export class MooringProductType {
  static ApiUrl = 'api/mooring-product-types';

  Id: number;
  Name: string;
  NameEn: string;
  NameEs: string;

  ArticleTypeId?: number;
  ArticleType?: MooringArticleType;

  AquacomId?: number;
}

@Expose()
export class AquacomComponentProduct {
  Id: number;
  Name: string;

  TypeId: number;
  TypeName: string;

  CategoryId: number;
  CategoryName: string;
}
