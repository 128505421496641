import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class CreateUpdateMaterialImpregnationUptakeFactors {
  @Expose()
  Id?: number;

  @Expose()
  MaterialId?: number;

  @Expose()
  ImpregnationTypeId?: number;

  @Expose()
  UptakeFactor?: number;
}

@Exclude()
export class MaterialImpregnationUptakeFactor extends CreateUpdateMaterialImpregnationUptakeFactors {
  @Expose()
  Material?: string;
}
