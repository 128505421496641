import { BaseEntity, BaseEntityWithMultilanguageName } from './BaseEntity';
import { Models } from './core';

export class Connection extends BaseEntity {
  public static ApiUrl = 'api/connections';
  ConnectionType?: ConnectionType;
  ConnectionTypeId: number;

  Net?: Models.Net;
  NetId: number;

  Coupling?: Coupling;
  CouplingId?: number;

  Quantity?: number;

  Load?: number;

  LoopId?: number;
  Loop?: Models.Loop;
}

export class ConnectionType extends BaseEntityWithMultilanguageName {
  public static ApiUrl = 'api/connectionTypes';
  HasLoad: boolean;
  Type: ConnectionTypes;
}

export class Coupling extends BaseEntity {
  public static ApiUrl = 'api/couplings';
  Title: string;
  Dim: number;
  MBL: number;
}

export enum ConnectionTypes {
  TopLoop = 1,
  AttachmentFloater = 2,
  ConnectionFloater = 3,
  AttachmentSinker = 4,
  ConnectionSinker = 5,
  LoopMageband = 6,
}
