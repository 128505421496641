import { Models } from './core';

export class ServiceStationLabourProducts {
  public static ApiUrl = 'api/service-station-labour-products';

  Id?: number;

  ServiceStationId: number;
  ServiceStation?: Models.ServiceStation;

  WashingProduct?: Models.Product;
  WashingProductId?: number;

  TestingProduct?: Models.Product;
  TestingProductId?: number;

  ReperationProduct?: Models.Product;
  ReperationProductId?: number;

  SewingMachineProduct?: Models.Product;
  SewingMachineProductId?: number;

  ImpregnationProduct?: Models.Product;
  ImpregnationProductId?: number;

  SpagettiProduct?: Models.Product;
  SpagettiProductId?: number;

  DeliveryProduct?: Models.Product;
  DeliveryProductId?: number;
}
