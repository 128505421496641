export const RopePlacement = {
  TOP_ROPE: 1,
  WATERLINE_ROPE: 2,
  VERTICAL_ROPE: 3,
  BOTTOM_ROPE: 4,
  CROSS_ROPE: 5,
  EXTRA_HORIZONTAL_ROPE: 6,
  EXTRA_ROPE_DELETED: 7,
  LIFTING_ROPE: 8,
  DEAD_FISH_ELEVATOR_ROPE: 9,
  LIFTING_ROPE_INNER_NET: 10,
  VERTICAL_ROPE_EKS_LIFTINGROPE: 11,
  CROSS_ROPE_INNER_BOTTOM: 12,
  CENTER_BASE: 13,
  SPAGETTI_ROPE: 14,
  JUMPING_FENCE: 15,
  EXTRA_ROPE: 16,
  UMBILICAL_CORD: 17,
} as const

export type RopePlacement = typeof RopePlacement[keyof typeof RopePlacement]
