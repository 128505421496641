export class LinkAnalysisDto {
  AnalysisId: number;
  AnalysisVariantId: number;
  Slot: number;
  Part: number;
}

export class UnlinkAnalysisDto {
  Slot: number;
  Part: number;
}

export const dimensionClass = {
  1: 'class0',
  2: 'classI',
  3: 'classII',
  4: 'classIII',
  5: 'classIV',
  6: 'classV',
  7: 'classVI',
  8: 'classVII',
  10: 'class0G',
  12: 'classA',
  13: 'classB',
  14: 'classC',
  15: 'classD',
} as const;

export type DimensionClassType = keyof typeof dimensionClass;
