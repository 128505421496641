import { BaseEntity } from './BaseEntity';
import { CategoryDimensionType } from './MooringArticleCategory';

export enum UnitOfMeasure {
  pieces = 1,
  meter = 2,
  kilogram = 3,
}

export class MooringSalesOrderLine extends BaseEntity {
  SalesOrderId: number;
  CertificateId: number;
  ArticleProductId: number;
  Quantity: number;
  UnitOfMeasure: UnitOfMeasure;
  ArticleNumber: string;
  SupplierName: string;
  BatchNumber: string;
  BatchNumberTo: string;
  ActualBatchNumber?: string;
}

export class CreateUpdateMooringSalesOrderLine {
  Id?: number;
  SalesOrderId: number;
  CertificateId: number;
  ArticleProductId: number;
  Quantity: number;
  UnitOfMeasure: UnitOfMeasure;
  Category: string;
  Type: string;
  Product: string;
  ProductId: number;
  ArticleNumber: string;
  PrimaryDimension: number;
  PrimaryDimensionType: CategoryDimensionType;
  SecondaryDimension?: number;
  SecondaryDimensionType?: CategoryDimensionType;
  Dimension: string;
  ActualBatchNumber?: string;
}

export class MooringSalesOrderLineListItem {
  Id: number;
  SalesOrderId: number;
  ArticleNumber: string;
  ProductName: string;
  BatchNumber: string;
  BatchNumberTo: string;
  ActualBatchNumber?: string;
  CertificateId: number;
  Quantity: number;
  ArticleProductId: number;
  UnitOfMeasure: UnitOfMeasure;
  Category: string;
  Type: string;
  Product: string;
  ProductId: number;
  PrimaryDimension: number;
  PrimaryDimensionType: CategoryDimensionType;
  SecondaryDimension?: number;
  SecondaryDimensionType?: CategoryDimensionType;
  Dimension: string;
}
