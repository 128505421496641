import { Models } from './core';
import { range, readOnly } from './restutil';

export class RopeTypeService {
  public static ApiUrl = 'api/ropetypesservice';

  @range(null, 200)
  Remark: string;
  @range(null, 200)
  RemarkEn: string;
  @range(null, 200)
  RemarkEs: string;
  PriceBasedOnWeight: boolean;

  WeightPerMeter?: number;
  RopeHardnessId?: number;
  RopeHardness: Models.RopeHardness;
  RopeDimensionId?: number;
  RopeDimension: Models.RopeDimension;

  ApprovedForNs9415: boolean;

  ProductId?: number;
  Product: Models.Product;

  @range(null, 100)
  Name: string;

  Id: number;
  Created?: Date;
  Updated?: Date;

  @readOnly()
  IsDeleted: boolean;
  Inactive?: boolean;
}
