import { Models } from './core';

export class ServiceSpecialProductWashProduct {
  public static ApiUrl = 'api/service-special-product-wash-products';

  Id: number;

  SpecialProductTypeId: number;
  SpecialProductType?: Models.SpecialProductType;

  ProductId: number;
  Product?: Models.Product;

  CompanyId: number;
  Company?: Models.Company;
}
