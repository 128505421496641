import { ProductMaterialCategory } from './ProductMaterialCategory';

export class ProductMaterialType {
  static ApiUrl = 'api/product-material-types';

  Id: number;
  Name: string;
  NameEn: string;
  NameEs: string;

  ProductMaterialCategoryId: number;
  ProductMaterialCategory?: ProductMaterialCategory;
}
