import { ApiProfile, apiRoute }  from 'services/rest-util';

export module SearchModels {  
  export class GroupedSearchResult {
    public static ApiUrl: string = 'api/search';

    Entity: string;
    Result: Array<SearchResult>;    
  }

  export class SearchResult {   
    Id: number;
    Entity: string;
    Result: string;
    ResultDetails: string;    
  }
}
