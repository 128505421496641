import { Models } from './core';

export class ServiceWashProductMatrix {
  public static ApiUrl = 'api/service-wash-product-matrix';

  Id: number;
  ProductId: number;
  Product: Models.Product;
  Depth: number;
  Circumference: number;
}
