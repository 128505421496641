import { Expose } from 'class-transformer';
import { MooringArticleCategory } from './MooringArticleCategory';

@Expose()
export class MooringArticleType {
  static ApiUrl = 'api/mooring-article-types';

  Id: number;
  Name: string;
  NameEn: string;
  NameEs: string;

  CategoryId: number;
  Category?: MooringArticleCategory;

  AquacomId?: number;
}

@Expose()
export class AquacomComponentType {
  Id: number;
  Name: string;

  CategoryId: number;
  CategoryName: string;
}
