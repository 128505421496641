import { Models } from './core';

export class ServiceInvoiceSpagettiProduct {
  public static ApiUrl = 'api/service-invoice-spagetti-products';

  Id: number;

  ProductId: number;
  Product?: Models.Product;

  IsForRopesDismounted: boolean;
  IsForRopesMounted: boolean;
}
