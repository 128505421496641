export class CreateOrderDto {
  public NetOfferId: number;
  public OrderNumber: string;
  public PurchaseOrderNumber: string;
  public ProjectNumber: string;
  public Items: Array<CreateOrderItemDto>;
  public PlannedDeliveryDate: Date;
}

export class CreateOrderItemDto {
 public ProducerId: number;
 public NetIdentifier: string; 
}
