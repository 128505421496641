import { Models } from './core';

export class ServiceWasteProductMatrix {
  public static ApiUrl = 'api/service-waste-product-matrix';

  Id: number;
  ProductId: number;
  Product: Models.Product;
  Depth: number;
  Circumference: number;
}
